.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
.modal-dialog-content {
    z-index: 1000;

}
.modal-dialog{
    min-width: 90vw;
}
.modal-content{
    min-height: 90vh;
}

.open {
    display: block!important;
}