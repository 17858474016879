.main {
    margin: 0;
    /* Space for fixed navbar */
    padding: 40px 0 0;
}

.c-card {
    background-color: white;
    border: 1px solid #c8c9cd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
}
