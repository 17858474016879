.user-avatar {
    border-radius: 50%;
    background-color: darkgray;
    width: 26px;
    height: 26px;
}

.user {
    color: white;
}

.header-user-block {
    height: 100%;
}
