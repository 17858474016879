.entity-graphic-view-main {
    padding: 10px;
}

.entity-view-svg {
    transform: scale(1, -1);
    padding: 5px;
    margin: 2px;
    border: solid 1px lightgray;
    max-width: 100%;
}