.menu-main {
    /*background-color: orange;*/
    min-height: calc(100vh - 40px);
    border-right: solid 1px lightgray;
}

.circle {
    border-radius: 50%;
    background-color: darkgray;
    width: 26px;
    height: 26px;
}

.menu-item-main {
    color: black;
}

.menu-item-main:hover {
    background-color: rgba(14, 114, 171, 0.31);
    color: black;
}