.auth-main {
    height: 100%;
}

.form-signin {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 420px;
    padding: 15px;
    /*margin: 0 auto;*/
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}
.user-login-error{
    color: red;
}

