/*
 * Navbar
 */

.header-settings-img {
    padding-right: 2px;
    max-height: 30px;
    max-width: 30px;
}
.header-main {
    background-color: #2c2c2c;
}