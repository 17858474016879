.user-card{
    z-index: 100;
    position: absolute;
    top: 33px;
    right: 0;
    min-width: 300px;
    min-height: 100px;
    border: solid 1px black;
    border-top: none;
    background: white;
    /*background: rgba(163, 160, 164, 0.51);*/
}